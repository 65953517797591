var _stats = null;
var _cmn = null;
var _manager = null;

/**
 * CmnManager
 * @class Page CmnManager
 * @returns
 */
var CmnManager = Klass.create();

CmnManager.extend(JqManager);

//definition start
CmnManager.extend({
/**
 * init
 */
init:function(){
	this.ua = getUA();
	this.isTop = false;
	this.webkit = ((this.ua["ua"]=="chrome")&&(this.ua["ua"]=="safari"))?true:false;
	this.html = {};
	this.body = {};
	this.anim_time = 600;
	this.anim_ease = "easeOutCubic";
	this.anim_ease2 = "easeOutQuart";
	this.anim_ease3 = "easeOutBack";
	this.anim_ease_in = "cubic-bezier(.75,.01,.69,.08)";
	this.anim_ease_out = "cubic-bezier(.3,1,.26,1)";
	this.anim_ease_inout = "cubic-bezier(.8,-0.01,.26,1)";
	this.is_down = false;
	this.is_finish = true;
	this.is_first = true;
	this.is_load = false;
	this.fps = 24;
	this.modern = false;
	this.raf = null;
	this.timer = null;
	if(this.ua["sp"]){

	}
},
/**
 * 設定
 */
set:function(){
	this.setParam();
	this.setObject();
	var oCom = new Command();
	this.start_command = oCom;
	oCom.execute();
},
/**
 * 開始
 */
start:function(){
	var oCom = new Command();
	this.start_command = oCom;
	oCom.call(this, this.setEvent, [],"");
	oCom.async(this, this.startContents, [null],"");
	oCom.callback(this,function(){
		_cmn.is_first = false;
		_cmn.start_command = null;
	},[],"");
	oCom.execute();
},
/**
 * setRouter
 */
setRouter:function(){
	$.pjax({
		area : '#main',
		wait: 0,
		ajax: { timeout: 3000},
		load: {
			script: true,
			reload: '[src*="script.min.js"],[src*="tmpl.js"]',
			css: false,
		}
	});
	// $('a:not([target])').on("click",_cmn.checkLink);
	$(document).bind('pjax:render', function(e){_cmn.startContents(e);});
	$(document).bind('pjax:fetch', function(e){_cmn.finishContents(e);});
},
/**
 * checkLink
 */
checkLink:function(e){
	//遷移中はキャンセル
	if(_cmn.is_load){ return false; }
	var $target = $(e.currentTarget);
	var strLink = $target.attr("href");
	if(!strLink){ return; }
	var oCom = new Command();
	// oCom.call(_cmn.header,_cmn.header.closeMenu,[],"");
	oCom.sleep(_cmn.anim_time*0.3);
	if($.url(strLink).attr("directory").replace(/\//g,"") !== $.url().attr("directory").replace(/\//g,"")){
//		oCom.async(_cmn,_cmn.finishContents,[],"");
//		oCom.async(_cmn,_cmn.startContents,[],"");
		oCom.call(this,function(){_cmn.is_load = true;},[],"");
		oCom.call(this,function(){$.pjax.click($target);},[],"");
	}
	oCom.execute();
	return false;
},
/**
 * setManager
 * local manager生成
 */
setManager:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	_manager = null;
	_manager = Manager.create();
	oCom.call(_manager,_manager.set,[],"");
	oCom.async(_manager,_manager.start,[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();

},
/**
 * startContents
 */
startContents:function(e){
	console.log("_cmn startContents");
	//終了処理を待って処理
	if(!_cmn.is_finish){
		setTimeout(function(e){_cmn.startContents(e);},300);
		return;
	}
	//ページ表示
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(_cmn,function(){ _cmn.is_finish = false; },[],"");
	oCom.call(_cmn,_cmn.passiveBody,[],"");
	oCom.call(_cmn,_cmn.presetContents,[],"");
	oCom.async(_cmn,_cmn.setManager,[],"");
	oCom.call(_cmn.loader, _cmn.loader.hide, [_cmn.anim_time],"");
	oCom.sleep(_cmn.anim_time);
	oCom.call(_cmn,_cmn.activeBody,[],"");
	oCom.async(_cmn,_cmn.showContents,[],"");
	oCom.call(_cmn,function(){_manager.after();},[],"");
	oCom.call(_cmn,function(){_cmn.is_load = false;},[],"");
//	if(!_cmn.is_first && $.url().attr("directory").replace("/","") === ""){
//		//TOP表示(2回目以降)
//		oCom.call(_cmn,_cmn.gotoTop,["#list",0,1],"");
//	}
	if(oAsync&&oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * finishContents
 */
finishContents:function(){
	console.log("_cmn finishContents");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(_manager,_manager.finish,[],"");
	oCom.call(_cmn,_cmn.passiveBody,[],"");
	oCom.async(_cmn,_cmn.hideContents,[],"");
	oCom.call(_cmn,function(){ _cmn.is_finish = true; },[],"");
	if(oAsync&&oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * presetContents
 */
presetContents:function(){
	console.log("_cmn presetContents");
	this.main = null;
	this.main = $("#main");
	this.main.removeClass("hide");
},
/**
 * contents表示
 */
showContents:function(){
	console.log("_cmn showContents");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this.loader, this.loader.hide, [this.anim_time],"");
	oCom.call(this,function(){ this.main.animate({opacity:1},this.anim_time,this.anim_ease); },[],"");
	oCom.sleep(this.anim_time);
	if(this.popup) {
		oCom.call(this.popup, this.popup.show, [500],"");
	}
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * contents非表示
 */
hideContents:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.async(this.loader, this.loader.hide, [1],"");
	oCom.call(this.loader, this.loader.show, [this.anim_time],"");
	oCom.call(this,function(){ this.main.animate({opacity:0},this.anim_time,this.anim_ease); },[],"");
	oCom.sleep(this.anim_time);
	oCom.call(this,function(){
		this.main.css({opacity:0});
		this.main.addClass("hide");
	},[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータ設定
 */
setParam:function(){
	var iVersion = ~~this.ua["ver"];
	this.modern = true;
	if(this.ua["ua"] === "ie" && iVersion <= 9){
		this.modern = false;
	}
	if(this.ua["sp"]){
		this.event_move = "touchmove";
		this.event_down = "touchstart";
		this.event_up = "touchend";
	}
},
/**
 * オブジェクト設定
 */
setObject:function(){
	this.loader = Loader.create($("#loader"));
	if(!localStorage.getItem('scc_visited')) {
		this.popup = Popup.create($("#popup"));
		localStorage.setItem('scc_visited', 'true');
	}
	// this.header = Header.create($("header"));
	// this.header.set();
	this.contents = $("#contents");
	this.main = $("#main");
},
/**
 * 初期状態設定
 */
setState:function(){
	console.log("_cmn setState");
	if(_cmn.ua["ua"] !== "ie"){
	}else{
	}
	if(_cmn.ua["sp"]){
		$("body").addClass("sp");
	}
	this.resize();
	$(".subset").remove();
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
/**
 * イベント設定
 */
setEvent:function(){
	this.resize();
	this.setWayPoint();
	this.setScroll();

	var oSelf = this;

	var strEvent = _cmn.ua["sp"] ? "resize orientationchange" : "resize";
	$(window).bind(strEvent,this.resize);
	smartRollover();

	// メニューの開閉
	var menu_is_open = false;

	//menuBtn
	$("#menuBtn").on("click" , function(e){
		if(menu_is_open){
			$(this).removeClass("open");
			$("#menu").removeClass("open");
			$("header").removeClass("color_is_white");
			menu_is_open = false;
		}else{
			$(this).addClass("open");
			$("#menu").addClass("open");
			$("header").addClass("color_is_white");
			menu_is_open = true;
		}
	});
	//menu_bg
	$("#menu_bg").on("click" , function(e){
		$("#menuBtn").removeClass("open");
		$("#menu").removeClass("open");
		$("header").removeClass("color_is_white");
		menu_is_open = false;
	});

	// searchAnim
	$(".search").on("click" , function(e){
		$(this).addClass("searchAnim");
	});

	// 検索回り
	$("#search_submit").on("click" , function(){
		if($(".search").hasClass("searchAnim")){
			$(".search_box").submit();
		}
	});

	// カテゴリセレクト
	$('.selectBtn').on("change" , function() {
		var target = $(this).val();
		location.href = target;
	});

	// selectBtn
	if(!_cmn.ua["sp"]){
		$(".selectBtn").prop('disabled', true);
		$(".pullDown_pc").css({
			"top" : $(".selectBtn_pc").height() + "px"
		});

		$(".selectBtn").css({
			"display" : "none"
		});

		var pullDownIsOpen = false;
		$(".selectBtn_pc").on("click" , function(){
			if(pullDownIsOpen){
				$(".pullDown_pc").css({
					"display" : "none"
				});
				pullDownIsOpen = false;
			}else{
				$(".pullDown_pc").css({
					"display" : "block"
				});
				pullDownIsOpen = true;
			}
		});

		$(".pullDown_pc").css({
			"top" : $(".selectBtn_pc").height() + "px",
			"display" : "none"
		});

		$(window).resize(function(e){
			$(".pullDown_pc").css({
				"top" : $(".selectBtn_pc").height() + "px"
			})
		});
	}else{
		$(".selectBtn_pc").css({
			"display" : "none"
		});
		$(".pullDown_pc").css({
			"display" : "none"
		});
	}

	// 現在のスクロール位置
	var nowScrollPos = $(window).scrollTop();
	// バナーがコンテンツ内のバナーエリア内にいるか（バナーとエリアが被っているか）
	var is_scroll_AreaIn = false;

	$(window).on('scroll', function() {

		oSelf.sectionNav();

		// 追従バナー
		// --------------------------------------
		// ページ表示時は最初からバナー表示。
		// 下スクロールでバナー非表示し
		// 上スクロールでバナー再び表示する。
		// コンテンツ内のバナーエリアと被った際は
		// 上スクロールでも非表示にする。
		// --------------------------------------

		if ($('#banner').length) {

			// ウインドウの高さ
			// ウインドウの高さが変わっても対応できるようにここで定義
			var windowHeight = $(window).innerHeight();

			// バナー要素
			var $banner = $("#banner");
			// バナー要素の高さ
			var bannerHeight = $banner.height();

			// バナーを隠したいエリアがページ内にあれば非表示
			// バナーを隠したいエリアに .bannerHideArea を付与すると反映されます
			// 同一ページ内に複数設置必要な場合は要調整
			if ($('.bannerHideArea').length) {
				$bannerHideArea = $('.bannerHideArea');
				// ページ一番上からのバナーエリア位置
				var bannerAreaPos = $bannerHideArea.offset().top;
				// バナーエリア要素の高さ
				var bannerAreaHeight = $bannerHideArea.height();

				// ウインドウの高さから、バナーエリアの高さを引いた高さ
				var nonBannerAreaHeight = windowHeight - bannerAreaHeight;

				// バナー非表示にしたいエリアの開始点
				var AreaIn = bannerAreaPos - windowHeight;
				// バナー非表示にしたいエリアの終了点
				var AreaOut = bannerAreaPos - nonBannerAreaHeight + bannerHeight;

				// バナーがバナーエリア内に被っているかを判定し、フラグ変更
				if(nowScrollPos >= AreaIn && AreaOut >= nowScrollPos) {
					// コンテンツ内のバナーエリア内にいる（バナーと被っている）
					is_scroll_AreaIn = true;
				} else {
					is_scroll_AreaIn = false;
				}

				// スクロール方向とフラグ値によって、バナーの表示非表示を切替
				if($(this).scrollTop() < nowScrollPos && is_scroll_AreaIn == false){
					//上スクロールかつバナーエリアと被ってなければ表示
					$banner.removeClass("banner_hide");
				}else{
					//下スクロール時は非表示
					$banner.addClass("banner_hide");
				}// ページ一番上からのバナーエリア位置
				var bannerAreaPos = $bannerHideArea.offset().top;
				// バナーエリア要素の高さ
				var bannerAreaHeight = $bannerHideArea.height();

				// ウインドウの高さから、バナーエリアの高さを引いた高さ
				var nonBannerAreaHeight = windowHeight - bannerAreaHeight;

				// バナー非表示にしたいエリアの開始点
				var AreaIn = bannerAreaPos - windowHeight;
				// バナー非表示にしたいエリアの終了点
				var AreaOut = bannerAreaPos - nonBannerAreaHeight + bannerHeight;

				// バナーがバナーエリア内に被っているかを判定し、フラグ変更
				if(nowScrollPos >= AreaIn && AreaOut >= nowScrollPos) {
					// コンテンツ内のバナーエリア内にいる（バナーと被っている）
					is_scroll_AreaIn = true;
				} else {
					is_scroll_AreaIn = false;
				}

				// スクロール方向とフラグ値によって、バナーの表示非表示を切替
				if($(this).scrollTop() < nowScrollPos && is_scroll_AreaIn == false){
					//上スクロールかつバナーエリアと被ってなければ表示
					$banner.removeClass("banner_hide");
				}else{
					//下スクロール時は非表示
					$banner.addClass("banner_hide");
				}
			} else {
				if($(this).scrollTop() < nowScrollPos){
					//上スクロールかつバナーエリアと被ってなければ表示
					$banner.removeClass("banner_hide");

				}else{
					//下スクロール時は非表示
					$banner.addClass("banner_hide");
				}
			}

			// 現在のスクロール位置の値を更新
			nowScrollPos = $(this).scrollTop();
		}


	});

	$(window).on("resize" , function(){
		oSelf.sectionNav();
	});
},
/**
 * setScroll
 */
setScroll:function(){
	//scroll
	$('a[href^="#"]').on("click",function(e) {
		console.log("setscrroll");
		var $target = $(e.currentTarget);
		var strHash = $target.attr("href");
		var iPos = $target.attr("data-pos");

		// ヘッダーの高さを考慮した位置に設定する
		var headerHeight = $("header").innerHeight();
		var marginBottom = 10;
		iPos = iPos ? ~~iPos : - (headerHeight + marginBottom);

		_cmn.gotoTop(strHash,iPos);
		return false;
	});
},
/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	var $waypointTarget;
	var headerHeight = $("header").height();
	var gloHeaderHeight = $(".global_header").height();

	if($("#wrapper").hasClass("topPage")){
		$waypointTarget = $("#article")
	}else{
		$waypointTarget = $("#article, #article_inner, #notfound, #mission, #careers, #workplace, #topics, #service, #case, #interview")
	}

	$waypointTarget.waypoint(function(direction){
		if(direction == "down"){
			$("header").addClass("color_is_black");
		}else{
			$("header").removeClass("color_is_black");
		}
	},{offset: headerHeight + gloHeaderHeight});

	$waypointTarget.waypoint(function(direction){
		if(direction == "down"){
			$(".backTopBtn").addClass("view");
		}else{
			$(".backTopBtn").removeClass("view");
		}
	},{offset: window.innerHeight});

	// ボタン - あしらいの線が一周回るアニメーション
	$(".moreBtn").waypoint(function(direction){
		$(this).removeClass("btnline");
		$(this).addClass("btnAnim");
	},{offset: offset1});

	function offset1(){
		return window.innerHeight * 0.7;
	}
},
/**
 * resize
 */
resize:function(e){
	// var oCom = new Command();
	var $nav = $("#menu").find(".menu_inner").find("nav");
	// oCom.call(this, function(){
	// 	$nav.css({scale:1});
	// }, [],"");
	// oCom.sleep(300);
	// oCom.call(this, function(){
		var iHeight = window.innerHeight;
		var navHeight = $nav.innerHeight();
		if(iHeight < navHeight) {
			var iScale = iHeight / navHeight;
			$nav.css({scale:iScale});
		}
	// }, [],"");
	// oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
track:function(e){
	var $target = $(e.currentTarget);
	var o = {
		type:"ua",
		target:$target.attr("target"),
		href:$target.attr("href"),
		mode:$target.attr("data-mode"),
		category:$target.attr("data-category"),
		action:$target.attr("data-action"),
		label:$target.attr("data-label"),
		val:~~$target.attr("data-val")
	};
	_cmn.postGA(o);
	if(o.target !== "_blank"){
		return false;
	}
},
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
/**
 * preload
 */
preload:function($container){
	var oThis = this;
	var aData = [];
	var oData = {};
	var oAsync = this.getAsyncCommand(arguments);
	var oPreloader = new createjs.LoadQueue(true);
	var manifest = [];
	var oURLBase = $.url();
	var strHostDomain = oURLBase.attr("host");
	var oImg = $container.find("img");
	var oURL = {};

	//img
	var iImgLen = oImg.length;
	for(var k = 0; k < iImgLen; k++){
		o = {};
		o.src = oImg[k].src;
		if(!o.src){
			continue;
		}

		o.id = oImg[k].src;
		o.type = createjs.PreloadJS.IMAGE;
		oURL = $.url(o.src);

		//クロスドメインは除外
		if(oURL.attr("file")&&(oURL.attr("host")===strHostDomain)){
			manifest.push(o);
		}
	}

	oPreloader.on("fileload",function(e) {
	},this);
	oPreloader.on("progress",function(e) {
		oThis.per_target = (e.loaded * 100) >> 0;
	},this);
	oPreloader.on("complete", function(e) {
		e.target.onFileLoad = null;
		e.target.onComplete = null;
		e.target.onProgress = null;
		oThis.per_target = 100;
	},this);
	oPreloader.loadManifest(manifest);
	oPreloader.load();

	//表示更新処理
	this.progress_timer = setTimeout(function(){
		oThis.updateProgress(oThis,oAsync);
	},50);
},
/**
 * progress
 */
updateProgress:function(oThis,oAsync){
	var iPer = oThis.per_target;
	if(iPer >= 100){
		oAsync.publish();
		oThis.progress_timer = null;
	}else{
		oThis.progress_timer = setTimeout(function(){
			oThis.updateProgress(oThis,oAsync);
		},50);
	}
},
/**
 * gotoTop
 */
gotoTop:function(hash,pos,time){
	//udp top title animation
	var iTime = typeof time !== "number" ? 800 : time;
	var $target = $(hash);
	var iY = 0;
	if((_cmn.ua["sp"] == true)){
		var isHtmlScroll = (function(){
		    var html = $('html'), top = html.scrollTop();
		    var el = $('<div/>').height(10000).prependTo('body');
		    html.scrollTop(10000);
		    var rs = !!html.scrollTop();
		    html.scrollTop(top);
		    el.remove();
		    return rs;
		})();

		if($target.attr("id") != undefined){
			iY = $target.offset().top;
		}
		$(isHtmlScroll ? 'html' : 'body').animate({scrollTop: iY + pos},iTime,_cmn.anim_ease);
		return false;
	}else{
		if($target.attr("id") != undefined){
			iY = $target.offset().top;
		}
		$('html,body').animate({scrollTop: iY + pos},iTime,_cmn.anim_ease);
		return false;
	}
},
/**
 * 画面の一番上に移動
 */
setTop:function(){
	scrollTo(0,0);
},
/**
 * 初回オープニング用resize
 */
opening_resize:function(){
},
/**
 * スクロール禁止
 */
passiveBody:function(){
	// $("body,html").css({"height":"","overflow":"hidden"});
},
/**
 * 全体表示
 */
activeBody:function(){
	// $("body,html").css({"height":"","overflow":""});
},
passiveScrollX:function(){
	$("body,html").css({"overflowX":"hidden"});
},
activeScrollX:function(){
	$("body,html").css({"overflowX":"auto"});
},
/**
 * INTERVIEW 詳細ページの時のみの挙動まわり
 */
sectionNav:function(){
	//------------------------------------------------------------------------
	// 下層ページ右上にある、ページ内アンカーnav（section_nav）の挙動
	// スクロールしてヘッダーの近くに来たら section_nav を固定し、
	// フッターまで来たら固定解除する
	//------------------------------------------------------------------------
	if ($('#section_nav').length) { // section_nav 要素があれば発動
		var $fixed_target = $('#section_nav'); // 状況に応じて固定させたい要素
		var winScroll = $(window).scrollTop(); // 元々の位置を取得。ページ全体の、どの位置(高さ)にいるか

		var targetHeight = $fixed_target.height(); // target要素の高さ
		var targetTopMargin = 140; // .section_nav の上にデフォルトで空けたいマージン量（px）

		var gloheaderHeight = $(".global_header").height(); // グローバルヘッダー要素の高さ

		var contentsTop = $('.footer__recruit').offset().top; // = contactより上のコンテンツ(#fv #talk)の高さ
		var contentsTop2 = $('.contentsBox').offset().top; // = contactより上のコンテンツ(#fv #talk)の高さ

		// target が固定終了にしたい要素とぶつかったら
		// footerPosition
		// フッター以前で固定解除したい要素があったら、それも加えた高さにする
		// 該当ページのCSSもいじる必要がある
		if ($('#fix_lift').length) {
			var fix_liftHeight = $("#fix_lift").height();
			var footerPos = winScroll + targetHeight + targetTopMargin + gloheaderHeight - contentsTop + fix_liftHeight;
		} else {
			var footerPos = winScroll + targetHeight + targetTopMargin + gloheaderHeight - contentsTop;
		}

		// ヘッダー付近
		var contents2Pos = winScroll + gloheaderHeight - contentsTop2;

		// target が固定終了にしたい要素とぶつかったら (footerPosが0以下になったら)
		if(contents2Pos >= 0){

			if(footerPos >= 0){
				$fixed_target.removeClass('fixed').addClass('footer_area');
				// INTERVIEW 詳細ページなら、footer_area に合わせて
				// 右上追従アンカーリンクの高さを変更する
				if($(".interview_details").length) {
					$(".footer_area").css({ "bottom" : $("#fix_lift").height() });
				}
			} else {
				// 一回値をリセットしないと、2回目以降の固定解除のタイミングがバグる
				if($(".interview_details").length) {
					$(".footer_area").css({ "bottom" : "auto" });
				}
				$fixed_target.addClass('fixed').removeClass('footer_area');
			}

		} else {
			$fixed_target.removeClass('fixed').removeClass('footer_area');
		}
	}
}
});
//definition end