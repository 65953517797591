/**
 * 
 * @param {*} canvasName    : animate描画用のcanvasに付けたID名
 * @param {*} compo         : animateから出力されるHTMLに記述してあるComposition名　例: C6A2E5684530A84C8ABADBCFAF475811
 * @param {*} className     : stage contentのクラス名、通常は、animateから書き出されたJSのファイル名から拡張子を抜いた名前が入る
 * @param {*} onComplete    : stageが初期化された後に実行される処理
 */
function initAnimate(canvasName, compo, className, onComplete){
    this.canvas;
    this.stage;
    this.exportRoot;
    this.fnStartAnimation;

    this.canPlay = false;

    var self = this;
    console.log("cue2");
    init();
    console.log("done");
    function init() {
        console.log("cue3");
        self.canvas = document.getElementById(canvasName);
        var comp=AdobeAn.getComposition(compo);
        var lib=comp.getLibrary();
        console.log("cue4");
        var loader = new createjs.LoadQueue(false);
        console.log("cue5");
        loader.addEventListener("fileload", function(evt){handleFileLoad(evt,comp)});
        loader.addEventListener("complete", function(evt){handleComplete(evt,comp)});
        var lib=comp.getLibrary();
        console.log("cue6");
        console.log(lib.properties.manifest);
        try{
            loader.loadManifest(lib.properties.manifest);
        }catch(e){
            console.log(e);
        }
        
        console.log("cue7");
    }
    function handleFileLoad(evt, comp) {
        var images=comp.getImages();	
        if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }	
    }
    function handleComplete(evt,comp) {
        //self function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
        var lib=comp.getLibrary();
        var ss=comp.getSpriteSheet();
        var queue = evt.target;
        var ssMetadata = lib.ssMetadata;
        for(i=0; i<ssMetadata.length; i++) {
            ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
        }
        self.exportRoot = new lib[className]();
        self.stage = new lib.Stage(self.canvas);	
        //Registers the "tick" event listener.
        self.fnStartAnimation = function() {
            self.stage.addChild(self.exportRoot);
            createjs.Ticker.framerate = lib.properties.fps;
            createjs.Ticker.addEventListener("tick", self.stage);

            self.canPlay = true;

            if(onComplete){
                onComplete();
            }
        }	    
        //Code to support hidpi screens and responsive scaling.
        AdobeAn.compositionLoaded(lib.properties.id);
        self.fnStartAnimation();
    }
}
