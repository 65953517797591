/**
 * Popup
 * @class Popup
 * @returns
 */
var Popup = Klass.create();

//definition start
Popup.extend(JqSprite);
Popup.extend({
/**
 * 初期処理
 */
init:function($o){
	this.o = $o;
	this.anim = false;
	this.list_y = 100;
	this.interval = 5000;
	this.anim_time = _cmn.anim_time;
	this.anim_ease_in = "easeInSine";
	this.anim_ease_out = "easeOutSine";
	this.anim_ease_bound = "easeOutBack";
	this.anim_ease_els = "easeOutElastic";
	this.timer = null;
	this.set();
},
/**
 * 設定
 */
set:function(){
	this.setObject.apply(this,[]);
	this.setState.apply(this,[]);
	this.setEvent.apply(this,[]);
},
show:function(iTime){
	var oSelf = this;
	oSelf.o.removeClass("hide");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this,function(){oSelf.o.removeClass("hide");},[],"");
	oCom.call(this,function(){oSelf.o.animate({y:0},iTime,oSelf.anim_ease_out);},[],"");
	oCom.sleep(iTime);
	oCom.call(this,function(){
		oSelf.showed = true;
	},[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
hide:function(iTime){
	var oSelf = this;
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this,function(){oSelf.o.animate({opacity:0},iTime,"linear");},[],"");
	oCom.sleep(iTime);
	oCom.call(this,function(){oSelf.o.addClass("hide");},[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
destract:function(){
	this.o.remove();
},
/**
 * オブジェクトセット
 */
setObject:function(){
	this.close_btn = this.o.find(".close_btn");
},
/**
 * パラメータセット
 */
setParam:function(){
},
/**
 * 初期位置設定
 */
setState:function(){
	this.o.css({y:"100%"});
	
},
/**
 * イベントセット
 */
setEvent:function(){
	var oThis = this;
	this.close_btn.on("click", function(){
		oThis.hide(300);
	});
	$(window).scroll(function(){
		if(oThis.showed) {
			oThis.hide(300);
		}
	});
},
/**
 * リサイズ
 */
resize:function(){
}
});
